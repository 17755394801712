import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import {
  blockedStatesForMortgageTools,
  apiUrl,
  loanAmountFactor,
  states,
} from '../../constants';
import { maxWidthForIsMobile } from '../../styles/GlobalStyles';
import ButtonStyled from '../Form/Button';
import StyledCurrencyField from '../Form/CurrencyField';
import StyledCustomSelect from '../Form/CustomSelect';
import StyledTextField from '../TextField';
import WaitlistModal from '../WaitlistModal';
import { theme } from '../../styles/GlobalTheme';
import HouseWithCoins from '../../images/HouseWithCoins.svg';

const yearsBeforeMovingList = [
  {
    value: 10,
    label: 'Typical (8 - 10 years)',
  },
  { value: 2, label: '2' },
  { value: 6, label: '6' },
  { value: 14, label: '14+' },
];

const creditScores = [
  {
    value: '760',
    label: 'Above 760',
  },
  {
    value: '740',
    label: '740-759',
  },
  {
    value: '720',
    label: '720-739',
  },
  {
    value: '700',
    label: '700-719',
  },
  {
    value: '680',
    label: '680-699',
  },
  {
    value: '660',
    label: '<680',
  },
];

export const primaryGoals = [
  {
    value: 'Minimize monthly payment (for sure)',
    label: 'Minimize monthly payment (for sure)',
  },
  {
    value: 'Minimize the total interest I pay',
    label: 'Minimize the total interest I pay',
  },
  {
    value:
      'Repay my debt as quickly as possible (with higher monthly payments)',
    label:
      'Repay my debt as quickly as possible (with higher monthly payments)',
  },

  {
    value:
      'Minimize my average monthly payment (but with risk - monthly payments can go up and down)',
    label:
      'Minimize my average monthly payment (but with risk - monthly payments can go up and down)',
  },
];

export const mortgageOptions = [
  {
    value: 'Purchase',
    label: 'Purchase',
  },
  {
    value: 'Refinance',
    label: 'Refinance',
  }
];

export const taxRates = [
  {
    value: 0.4,
    label: '40%',
  },
  {
    value: 0.35,
    label: '35%',
  },

  {
    value: 0.3,
    label: '30% (typical)',
  },
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.2,
    label: '20%',
  },
  {
    value: 0.15,
    label: '15%',
  },
  {
    value: 0,
    label: '0%',
  },
];

function Home({
  className,
  setData,
  setStep,
  getBestRates,
  data: { goal, state, purchasePrice, yearsBeforeMoving, tool, loanAmount, hasOffer, rate, loanProgram, yearsSinceMortgage, creditScore, taxRate, totalFees, pointFees, purpose },
  addMessage,
}) {
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const filteredStates = states.filter(
    (state) => !blockedStatesForMortgageTools.includes(state)
  );

  const handleCurrencyFieldClick = (event) => {
    event.target.setSelectionRange(
      event.target.value.length,
      event.target.value.length
    );
  };

  const handleNextStep = useCallback(() => {
    if (blockedStatesForMortgageTools.includes(state)) {
      return setExitModalOpen(true);
    }

    if (tool === 'COMPARE_OFFERS' && purchasePrice < loanAmount) {
      return addMessage(
        'The value of your house must be greater than loan amount',
        false
      );
    }

    if (tool === 'REFINANCE') {
      setData({ loanAmount: purchasePrice * loanAmountFactor });
    }

    setStep('loadingOffers');
    setData((prevData) => ({ ...prevData, rate }));

    getBestRates(rate);
  }, [state, purchasePrice, rate, loanAmount, purpose, state, yearsBeforeMoving, creditScore, taxRate, goal, loanProgram, totalFees, yearsSinceMortgage, pointFees]);

  const handleSignUp = async (email) => {
    try {
      setExitModalOpen(false);

      const response = await fetch(`${apiUrl}/external/addUserToWaitList`, {
        method: 'POST',
        body: JSON.stringify({ usState: state, email }),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });

      const { message } = await response.json();

      addMessage(message);
    } finally {
    }
  };

  let title = '';

  switch (tool) {
    case 'REFINANCE':
      title = 'Should I refinance my home?';
      break;
    case 'COMPARE_OFFERS':
      title = <span style={{ textAlign: 'center', display: 'block' }}>Optimize Your Mortgage<br /><span style={{ color: theme.blue4 }}>Compare Costs Instantly</span></span>;
      break;
    case 'SHOP_OFFERS':
      title = 'Shop for the best mortgage rates';
      break;
  }

  return (
    <div className={className}>
      <h1>{title}</h1>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box mb={2}>{tool === 'SHOP_OFFERS' ? <h3>Home Details</h3> : <h3>Your Home</h3>}</Box>
          {tool !== 'REFINANCE' && <Box mb={4}>
            <StyledCustomSelect
              label="Loan Purpose"
              onChange={({ target }) => setData({ purpose: target.value })}
              options={mortgageOptions}
              value={purpose}
            />
          </Box>}

          <Box mb={4}>
            <StyledCustomSelect
              label="State"
              onChange={({ target }) => setData({ state: target.value })}
              options={filteredStates.map((state) => ({
                value: state,
                label: state,
              }))}
              value={state}
            />
          </Box>

          <Box mb={4}>
            <StyledCurrencyField
              label={tool === 'REFINANCE' ? "Value of your Home" : "Property Value"}
              adornment="$"
              setValue={(value) => setData({ purchasePrice: value })}
              value={purchasePrice}
              placeholder="300,000"
            />
          </Box>

          {tool === 'SHOP_OFFERS' && <Box my={4}>
            <StyledCurrencyField
              label="Loan Amount"
              setValue={(value) => setData({ loanAmount: value })}
              value={loanAmount}
              placeholder="250,000"
              adornment={'$'}
              onChange={handleCurrencyFieldClick}
            />
          </Box>}

          {tool !== 'COMPARE_OFFERS' && tool != 'REFINANCE' && <Box mb={4}>
            <StyledCustomSelect
              label="Years Before Moving"
              onChange={({ target }) =>
                setData({ yearsBeforeMoving: target.value })
              }
              options={yearsBeforeMovingList}
              value={yearsBeforeMoving}
              className="select"
            />
          </Box>}

          <h3>Your Finances</h3>
          <Box my={4}>
            <StyledCustomSelect
              label="Credit Score"
              defaultValue={'Select your credit score'}
              value={creditScore}
              options={creditScores}
              onChange={({ target }) => setData({ creditScore: target.value })}
            />
          </Box>

          {tool === 'REFINANCE' && <Box mb={4}>
            <StyledCustomSelect
              label="Tax Bracket"
              defaultValue={'Select a tax bracket'}
              value={taxRate}
              options={taxRates}
              onChange={({ target }) => setData({ taxRate: target.value })}
            />
          </Box>}

          {tool !== 'REFINANCE' && tool != 'COMPARE_OFFERS' && <Box mb={4}>
            <StyledCustomSelect
              label="Primary Goal"
              defaultValue={'Select a goal'}
              value={goal}
              options={primaryGoals}
              onChange={({ target }) => setData({ goal: target.value })}
            />
          </Box>}

          {tool === 'REFINANCE' && <h3>Your Mortgage Details</h3>}
          {tool === 'COMPARE_OFFERS' && <h3>Your Offer Details</h3>}
          {tool !== 'SHOP_OFFERS' && <Box my={4}>
            <StyledCurrencyField
              label={tool === 'REFINANCE' ? "Existing Mortgage Loan Amount" : "Existing Offer Loan Amount"}
              setValue={(value) => setData({ loanAmount: value })}
              value={loanAmount}
              placeholder="250,000"
              adornment={'$'}
              onChange={handleCurrencyFieldClick}
            />
          </Box>}

          {tool !== 'SHOP_OFFERS' && <Box mb={4}>
            <StyledCurrencyField
              label={tool === 'REFINANCE' ? "Rate on Existing Mortgage" : "Rate on Existing Offer"}
              setValue={(value) => {
                setData({ rate: value });
              }}
              value={rate}
              placeholder="6.5"
              adornment={'%'}
              onChange={handleCurrencyFieldClick}
            />
          </Box>}

          {tool === 'REFINANCE' && <Box mb={4}>
            <StyledCustomSelect
              label="Mortgage Type"
              value={loanProgram}
              onChange={({ target }) => setData({ loanProgram: target.value })}
              options={[
                {
                  value: '30 yr fixed',
                  label: '30 Year Fixed',
                },
                {
                  value: '15 yr fixed',
                  label: '15 Year Fixed',
                },
                {
                  value: '5/1 ARM',
                  label: '5-1 ARM',
                },
                {
                  value: 'Other',
                  label: 'Other',
                },
                {
                  value: 'Not sure',
                  label: 'Not sure',
                },
              ]}
            />
          </Box>}

          {tool === 'REFINANCE' && <Box mb={4}>
            <StyledTextField
              label="Age of Current Mortgage"
              setValue={(value) => setData({ yearsSinceMortgage: value })}
              value={yearsSinceMortgage}
              adornment={'$'}
              onChange={handleCurrencyFieldClick}
              type="number"
              passwordsMatch={'/d+/g'}
            />
          </Box>}

          {tool === 'COMPARE_OFFERS' && <Box mb={4}>
            <StyledCurrencyField
              label="Lender/Origination Fees"
              setValue={(value) => setData({ totalFees: value })}
              value={totalFees}
              adornment={'$'}
              onChange={handleCurrencyFieldClick}
            />
          </Box>}

          {tool === 'COMPARE_OFFERS' && <Box mb={4}>
            <StyledCurrencyField
              label="Points (or Credits) in Dollars"
              setValue={(value) => setData({ pointFees: value })}
              value={pointFees}
              adornment={'$'}
              onChange={handleCurrencyFieldClick}
            />
          </Box>}

          {tool === 'COMPARE_OFFERS' && <Box mb={4}>
            <StyledCustomSelect
              label="Loan Period"
              value={loanProgram}
              onChange={({ target }) => setData({ loanProgram: target.value })}
              options={[
                {
                  value: '30 yr fixed',
                  label: '30 Year Fixed',
                },
                {
                  value: '15 yr fixed',
                  label: '15 Year Fixed',
                },
                {
                  value: '5/1 ARM',
                  label: '5-1 ARM',
                },
                {
                  value: 'Other',
                  label: 'Other',
                },
                {
                  value: 'Not sure',
                  label: 'Not sure',
                },
              ]}
            />
          </Box>}


          <div id="action-container">
            <ButtonStyled
              id="back-button"
              onClick={() => navigate(-1)(hasOffer ? 'existingOffer' : 'scenario')}
            >
              Back
            </ButtonStyled>

            <ButtonStyled
              disabled={
                !state || !purchasePrice || !yearsBeforeMoving || !loanAmount
              }
              id="call-to-action-button"
              onClick={handleNextStep}
              variant="contained"
              style={{ background: theme.green8, borderColor: theme.green8 }}
            >
              Submit
            </ButtonStyled>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          {tool === 'REFINANCE' && <Box>
            <h3>When should you refinance your home?</h3>
            <p>Fill out your information on the left to find out if now is a good time to refinance your home.</p>
            <p>We shop around across lenders to find your best rate.  Property state, credit score, loan amount and property value determine the price of your potential new mortgage.</p>
            <p>Tax bracket and how old your existing mortgage is is needed to estimate the tax benefits (as the interest is tax deductible).</p>
            <p>We then take the best rate we could find and compare against the up front cost of getting a new mortgage and give you a quick answer.</p>
          </Box>}
          {tool === 'COMPARE_OFFERS' && <Box>
            <h3>Quickly compare offers</h3>
            <p>Fill out your information on the left and we use that to shop around for you and give you a quick price comparison.  Property state, credit score, loan amount and property value help us shop for mortgages.</p>
            <p>Then regardless of the particular offer you already have, we convert the price for an apples-to-apples comparison - holding the interest rate the same and showing you how the upfront cost would vary.</p>
            <p>We then illustrate where your offer stands in the market.</p>
          </Box>}
          {tool === 'SHOP_OFFERS' && <Box>
            <h3>Get the best price on the right mortgage</h3>
            <p>Fill out your information on the left and we use that to shop around for you across lenders.  Property state, credit score, loan amount and property value determine the price of your potential new mortgage.</p>
            <p>The amount of time you think you'll spend in your new home (and loan) help decide what type of mortgage is the best fit for you.</p>
            <p>We then get back to you with best price we can find.</p>
          </Box>}
          <p style={{ fontWeight: 'bold', background: '#eee', border: '1px solid #ddd', borderRadius: '4px', textAlign: 'center', padding: '12px 0' }}>Awaiting Input...</p>
          <img src={HouseWithCoins} style={{ width: '80%', margin: '24px 10%' }} />
        </Grid>
      </Grid>

      <WaitlistModal
        exitModalOpen={exitModalOpen}
        setExitModalOpen={setExitModalOpen}
        handleSignUp={handleSignUp}
      />
    </div>
  );
}

/**
 * The Final Exported Container
 */
const StyledHome = styled(Home)`
  ${StyledCustomSelect},
  ${StyledCurrencyField} {
    input {
      color: black;
    }
  }

  & > h1 {
    margin-top: 32px;
    margin-bottom: 46px;
    font-size: 2.5em;
    font-weight: 400;
    font-family: Majorant-Medium;
  }

  & > span {
    font-weight: 400;
    font-family: Majorant-Medium;
    display: block;

    margin-top: 32px;
    margin-bottom: 16px;
  }

  & h3 {
    margin-bottom: 16px;
  }

  & ul {
    list-style-type: disc;
    margin-left: 16px;
    margin-bottom: 8px;
  }

  & p {
    margin-bottom: 16px;
    line-height: 175%;
    font-family: Majorant-Regular;
  }

  #action-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 36px;
    gap: 10px;

    button {
      height: 43px;
    }

    #back-button {
      width: 159px;
    }

    #call-to-action-button {
      width: 177px;
    }

    @media screen and (max-width: ${maxWidthForIsMobile}) {
      flex-direction: column;

      button,
      #call-to-action-button,
      #back-button {
        width: 100%;
      }

      ${StyledCustomSelect},
      ${StyledCurrencyField} {
        max-width: 100%;
      }
    }
  }
`;

StyledHome.displayName = 'Home';

export default StyledHome;
