/**
 * React and friends
 */
import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';

import { trackEvent } from '../analytics';

/**
 * Custom components for the screens
 */
import HomeScreen from '../components/Tool/Home';
import AdditionalInfoScreen from '../components/Tool/AdditionalInfo';
import OfferFoundScreen from '../components/Tool/OfferFound';
import LoadingOffersScreen from '../components/Tool/LoadingOffers';
import ResultOptionsScreen from '../components/Tool/ResultOptions';
import { API_VERSION, apiInternalUrl } from '../constants';
import YourLoanScreen from '../components/MortgageRefinance/YourLoan';
import FinancialSituationScreen from '../components/MortgageRefinance/FinancialSituation';
import { GoalLoanProductMapper } from './useMortgageAdvisor';

const GeneralContext = createContext();

const { Provider, Consumer } = GeneralContext;

export const SnackbarConsumer = Consumer;

/**
 * Hook provider for the mortgage refinance tool
 */
export const RefinanceToolProvider = ({ children }) => {
  /**
   * State for the data
   */
  const [data, setValue] = useState({
    hasOffer: false,
    loanAmount: 250000,
    loanProgram: '30 yr fixed',
    ltv: 0.7,
    purchasePrice: '',
    rate: '',
    state: '',
    creditScore: '700',
    totalFees: 1500,
    purpose: 'Refinance',
    numberOfDays: 4,
    N: 2000,
    pointFees: 0,
    yearsBeforeMoving: 10,
    goal: 'Minimize monthly payment (for sure)',
    tool: 'REFINANCE',
    yearsSinceMortgage: 5,
    taxRate: 0.3,
    shouldIRefinanceAnswer: false,
    financeAnswers: [],
  });

  /**
   * State to keep the best rates for user
   */
  const [bestRates, setBestRates] = useState([]);

  /**
   * state for the step
   */
  const [StepComponent, setStepComponent] = useState(steps['home']);

  const setStep = (nextStep) => {
    setStepComponent(steps[nextStep]);
  };

  const setData = useCallback(
    (value) => {
      if (value.rate !== '') {
        setValue({ ...data, ...value });
      }
    },
    [data]
  );

  const getBestRates = useCallback(() => {
    (async () => {
      try {
        const [goal, loanProductString] = GoalLoanProductMapper[data.goal] || [
          'MIN_MONTHLY',
          'Fixed30Year',
        ];
        const body = {
          ...data,
          ltv: data.loanAmount / data.purchasePrice,
          loan_amount: data.loanAmount,
          oldSimpleRate: data.rate,
          max_points: 10,
          min_points: -10,
          goal,
          loanProductString,
        };

        const response = await fetch(
          `${apiInternalUrl}/${API_VERSION.V1}/mortgage/shouldirefinance`,
          {
            method: 'POST',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' },
          }
        );

        if (response.status !== 200)
          throw new Error('Error getting best rates');

        const responseData = await response.json();
        trackEvent('Used Finance Tool', { type: 'REFINANCE_TOOL' });

        setData({ ...responseData });
        setBestRates(responseData.bestRateLenderArray, data || []);

        setStep('offerFound');
      } catch (err) {
        console.error(err);
        setStep('offerFound');

        return null;
      }
    })();
  }, [data]);

  /**
   * Memoized value for callbacks
   */
  const value = useMemo(
    () => ({
      setData,
      data,
      StepComponent,
      setStep,
      getBestRates,
      bestRates,
    }),
    [setData, setStep, data, StepComponent, getBestRates, bestRates]
  );

  return <Provider value={value}>{children}</Provider>;
};

/**
 * Context export to use hook
 */
export default () => useContext(GeneralContext) || {};

/** Map for screens */
const steps = {
  home: HomeScreen,
  yourLoan: YourLoanScreen,
  financialSituation: FinancialSituationScreen,
  additionalInfo: AdditionalInfoScreen,
  offerFound: OfferFoundScreen,
  loadingOffers: LoadingOffersScreen,
  resultOptions: ResultOptionsScreen,
};
